var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.TextDetail && Object.keys(_vm.TextDetail).length),expression:"TextDetail && Object.keys(TextDetail).length"}],staticClass:"text-detail",class:{'blured': _vm.user && !Object.keys(_vm.user).length}},[(_vm.banners && _vm.banners.length)?_c('TextMaterialsSlider',{attrs:{"banners":_vm.banners,"title":_vm.TextDetail.name,"color":'#fff'}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__title",domProps:{"innerHTML":_vm._s(_vm.TextDetail.name)}})]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Библиотека', link: { name: 'Library' } },
        {
          name: 'Публикации',
          link: {
            name: 'TextMaterials',
          },
        },
        {
          name: _vm.TextDetail.name,
        } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row mt-4"},[_c('div',[_c('div',{staticClass:"material__tags-wrap"},[_c('div',{staticClass:"material__tags"},[(_vm.TextDetail.type)?_c('div',{staticClass:"material__tag"},[_vm._v(" "+_vm._s(_vm.TextDetail.type.name)+" ")]):_vm._e(),_vm._l((_vm.TextDetail.nosology),function(nosology){return _c('div',{key:("nos" + (nosology.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(nosology.title)}})}),_vm._l((_vm.TextDetail.disease),function(disease){return _c('div',{key:("dis" + (disease.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(disease.title)}})}),(_vm.TextDetail.is_new && false)?_c('div',{staticClass:"material__tag material__tag_new"},[_vm._v(" Новинка ")]):_vm._e()],2)]),(
              _vm.TextDetail.content &&
              _vm.TextDetail.presentations &&
              !_vm.TextDetail.presentations.length
            )?_c('TextDetailBody',{attrs:{"content":_vm.TextDetail.content}}):_vm._e(),(_vm.user && !Object.keys(_vm.user).length )?_c('div',{staticClass:"button button_pink d-inline-flex px-8",on:{"click":_vm.openPopup}},[_vm._v(" Читать ")]):_vm._e(),(_vm.TextDetail.presentations && _vm.TextDetail.presentations.length)?_c('div',{staticClass:"text-detail__body"},_vm._l((_vm.TextDetail.presentations),function(presentation,ind){return _c('Presentations',{key:ind,attrs:{"color":'#830051',"autoplay":'false',"Slides":presentation.pages}})}),1):_vm._e()],1),_c('div',{staticClass:"sticky"},[(_vm.asideItems && _vm.asideItems.length && false)?_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"nosologyClick":_vm.nosologyClick}}):_vm._e()],1)]),_c('div',{staticClass:"text-detail__buttons"},[_c('div',{staticClass:"text-detail__load button button_pink",on:{"click":function($event){return _vm.back()}}},[_c('span',{staticClass:"mr-2 mt-n-1"},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 1.5L1.5 9L9 16.5","stroke":"white","stroke-width":"1.5","stroke-linecap":"square"}})])]),_vm._v(" Назад ")]),_c('div',{staticClass:"text-detail__up button button_empty-pink",on:{"click":function($event){return _vm.toTop()}}},[_vm._v(" К началу страницы "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"18","height":"10","viewBox":"0 0 18 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.25 8.75012L8.75 1.25012L16.25 8.75012","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])])])]),(
        _vm.TextDetail.nosology &&
        _vm.TextDetail.nosology.length &&
        _vm.sliderItems.length
      )?_c('LibrarySlider',{attrs:{"nosology":_vm.TextDetail.nosology[0],"items":_vm.sliderItems,"page":'TextDetail'},on:{"clickMaterials":_vm.clickMaterials,"toNosol":_vm.toNosol}}):_vm._e()],1),(_vm.popup)?_c('PopupEnd',{attrs:{"eventName":'nf-1',"backUrl":{ name: 'NF-1' }},on:{"close":_vm.closePopup}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }