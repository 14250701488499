<template>
  <div>
    <div
      class="text-detail"
      v-show="TextDetail && Object.keys(TextDetail).length"
      :class="{'blured': user && !Object.keys(user).length}"
    >
      <TextMaterialsSlider
        v-if="banners && banners.length"
        :banners="banners"
        :title="TextDetail.name"
        :color="'#fff'"
      />
      <div class="content" v-else>
        <div class="text-detail__title" v-html="TextDetail.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Библиотека', link: { name: 'Library' } },
          {
            name: 'Публикации',
            link: {
              name: 'TextMaterials',
            },
          },
          {
            name: TextDetail.name,
          },
        ]"
      />
      <div class="content">
        <div class="text-detail__row mt-4">
          <div>
            <div class="material__tags-wrap">
              <div class="material__tags">
                <div class="material__tag" v-if="TextDetail.type">
                  {{ TextDetail.type.name }}
                </div>
                <div
                  class="material__tag"
                  v-for="nosology in TextDetail.nosology"
                  :key="`nos${nosology.id}`"
                  v-html="nosology.title"
                ></div>
                <div
                  class="material__tag"
                  v-for="disease in TextDetail.disease"
                  :key="`dis${disease.id}`"
                  v-html="disease.title"
                ></div>
                <div
                  class="material__tag material__tag_new"
                  v-if="TextDetail.is_new && false"
                >
                  Новинка
                </div>
              </div>
            </div>

            <TextDetailBody
              v-if="
                TextDetail.content &&
                TextDetail.presentations &&
                !TextDetail.presentations.length
              "
              :content="TextDetail.content"
            />
            <div v-if="user && !Object.keys(user).length " class="button button_pink d-inline-flex px-8" @click="openPopup">
              Читать
            </div>
            <div
              class="text-detail__body"
              v-if="TextDetail.presentations && TextDetail.presentations.length"
            >
              <Presentations
                :color="'#830051'"
                :autoplay="'false'"
                :Slides="presentation.pages"
                v-for="(presentation, ind) in TextDetail.presentations"
                :key="ind"
              />
            </div>
          </div>

          <div class="sticky">
            <RightAside
              v-if="asideItems && asideItems.length && false"
              :items="asideItems"
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @nosologyClick="nosologyClick"
            />
          </div>
        </div>
        
        <div class="text-detail__buttons">
          <div @click="back()" class="text-detail__load button button_pink">
            <span class="mr-2 mt-n-1">
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1.5L1.5 9L9 16.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
            Назад
          </div>
          <div
            class="text-detail__up button button_empty-pink"
            @click="toTop()"
          >
            К началу страницы
            <span class="ml-2">
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="
          TextDetail.nosology &&
          TextDetail.nosology.length &&
          sliderItems.length
        "
        @clickMaterials="clickMaterials"
        @toNosol="toNosol"
        :nosology="TextDetail.nosology[0]"
        :items="sliderItems"
        :page="'TextDetail'"
      />
    </div>
    <PopupEnd
      :eventName="'nf-1'"
      :backUrl="{ name: 'NF-1' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import "@/assets/scss/detail_content.scss";
import "@/assets/scss/clinical_case.scss";
import RightAside from "../../components/pageComponents/RightAside.vue";
import TextDetailBody from "../../components/pageComponents/TextDetailBody.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Presentations from "../../components/pageComponents/Presentations.vue";
import TextMaterialsSlider from "@/components/main/TextMaterialsSlider.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";

export default {
  metaInfo() {
    if (this.TextDetail.name) {
      return { title: this.TextDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.TextDetail.name };
    }
  },
  name: "TextDetail",
  components: {
    Breadcrumbs,
    Choch,
    RightAside,
    LibrarySlider,
    Error,
    TextDetailBody,
    Presentations,
    TextMaterialsSlider,
    PopupEnd,
  },
  data: () => ({
    items: [],
    index: null,
    popup: false,
  }),
  computed: {
    ...mapGetters(["user", "TextList"]),
    TextDetail() {
      return {
        name: this.user && Object.keys(this.user).length ? "Клинический случай Нейрофиброматоз 1 типа" : "Клинический случай",
        slug: "klinicheskij-sluchaj-nejrofibromatoz-1-tipa",
        nosology: [],
        disease: [],
        presentations: [],
        banner_mobile: null,
        banner_tablet: null,
        banner_desktop: null,
        favorite: false,
        watch_later: false,
        type: {
          id: 2,
          name: "Клинические случаи",
          slug: "clinical_cases",
        },
        file: null,
        views: 85,
        announce: {
          url: "https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/announce/2023/11/08/az-background_s3glIcS.jpg",
          size: "8.2 Кб",
          extension: "jpg",
        },
        is_new: true,
        content:
          '<div class="new"><!-- самая первая картинка --> <img class="main-image mb-12" src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/clinical_cases/nf-1/main-img.jpg" /></div>\r\n<div id="NFtabs" class="test-block"></div>\r\n<div id="NFContent" class="test-block mt-12"></div>',
        preview: "",
        active: true,
      };
    },
    sliderItems() {
      return this.TextList
        ? this.TextList.filter((el) => el.slug !== this.TextDetail.slug)
        : [];
    },
    filter() {
      if (this.TextDetail && Object.keys(this.TextDetail).length) {
        return {
          therapeutic_areas: [this.TextDetail.nosology[0]?.slug],
        };
      } else {
        return {};
      }
    },
    banners() {
      let arr = [];
      if (
        this.TextDetail.banner_desktop ||
        this.TextDetail.banner_tablet ||
        this.TextDetail.banner_mobile
      ) {
        arr.push({
          image_desktop: this.TextDetail.banner_desktop,
          image_tablet: this.TextDetail.banner_tablet,
          image_mobile: this.TextDetail.banner_mobile,
        });
      }
      return arr;
    },
    asideItems() {
      if (this.TextDetail && Object.keys(this.TextDetail).length) {
        return [
          {
            title: "Препараты АЗ",
            // descr: this.TextDetail.nosology.map((el) => el.title).join(","),
            image: require("@/assets/img/cardio-img/aside-1.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-1_small.jpg"),
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: this.TextDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Календарь мероприятий",
            image: require("@/assets/img/cardio-img/aside-2.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-2_small.jpg"),
            event: "calendarClick",
            to: {
              name: "Events",
              query: {
                therapeutic_areas: this.TextDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Терапевтические <br>области",
            image: require("@/assets/img/cardio-img/aside-4.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-4_small.jpg"),
            event: "nosologyClick",
            to: { name: "Nosology" },
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    closePopup() {
      this.popup = false;
    },
    openPopup() {
       this.popup = true;
    },
    back() {
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        this.$router.push({ name: "TextMaterials" });
      } else {
        this.popup = true;
      }
    },
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial page medications click", {
          "textmaterial page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial  page events click", {
          "textmaterial  page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    nosologyClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial page therapeutic areas click", {
          "textmaterial page therapeutic areas click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "textmaterial page more materials click", {
          "textmaterial page more materials click": {
            "textmaterial page name": name,
            ...this.$root.ymFields,
          },
        });
      }
    },
    toNosol(title, to) {
      const vm = this;

      ym(
        91468266,
        "reachGoal",
        "textmaterial page current therapeutic areas click",
        {
          "textmaterial page current therapeutic areas click": {
            "therapeutic area": title,
            ...this.$root.ymFields,
          },
        }
      );
      this.$router.push(to).catch(() => {});
    },
  },
  async mounted() {
    setTimeout(() => {
      if (this.user && Object.keys(this.user).length) {
        // this.$router.push({
        //   name: "TextDetail",
        //   params: { slug: this.TextDetail.slug },
        // });
      } else {
        this.popup = true;
      }
    }, 500);
    const vm = this;
    if (this.TextDetail.file && Object.keys(this.TextDetail.file).length) {
      window.open(`${this.TextDetail.file.url}`, "_self");
    }
    
  },
  watch: {
    
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__body {
    margin-top: 64px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>

<style lang="scss">
.blured {
  & .main-image {
    display: none;
  }

  & .nf-other {
    display: none;
  }

  & .nf-tabs {
    filter: blur(3px);
    pointer-events: none;
    user-select: none;
  }
}
</style>
